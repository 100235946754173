.success-modal__wrapper {
  position: relative;
  padding: 64px 56px;

  .close__btn {
    position: absolute;
    top: 27px;
    right: 27px;
    cursor: pointer;
  }

  .success-message__content {
    width: 100%;
    max-width: 544px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .btn__wrapper {
      margin-top: 32px;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 52px 18px 47px;

    .close__btn {
      position: absolute;
      top: 24px;
      right: 18px;
    }
  }
}
