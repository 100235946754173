.checkbox__item {
  cursor: pointer;
  display: flex;
  align-items: center;

  &--checked {
    .checkbox__item-box::before {
      opacity: 1;
      transform: scale(1);
      background-color: #5BCBC9;
    }
  }

  &-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    background: var(--white);
    border: 1px solid var(--text);
    border-radius: 2px;

    &::before {
      content: "";
      width: 14px;
      height: 14px;
      opacity: 0;
      transform: scale(0.8);
      transition: transform 0.4s, background-color 0.25s linear, opacity 0.2s;
    }
  }

  &-input {
    display: none;
  }
  &-label {
    display: block;
    margin-left: 12px;
    color: #101821;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.03em;

    color: var(--black);
  }
}
