.registration__links {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .sign-in {
    padding-bottom: 50px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #222222;

    @media screen and (min-width: 1024px) {
      font-size: 18px;
    }

    .MyLink__registration {
      margin-left: 12px;
    }
  }
}
