.form-step__wrapper {
  width: 100%;

  .observer-fields__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px 0;
    padding: 32px 24px 40px;
    background-color: var(--white);
    border-radius: 12px;

    .observer__number {
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.02em;
      color: var(--black);
    }

    .fields__container {
      display: flex;
      justify-content: space-between;

      .field__item {
        width: 32.5%;
      }
    }
  }
  @media screen and (max-width: 1340px) {
    padding: 0 16px;
  }

  @media screen and (max-width: 767px) {
    padding-top: 12px;
    .observer-fields__wrapper {
      padding: 32px 16px 40px;

      .fields__container {
        flex-direction: column;

        .field__item {
          width: 100%;
        }
      }
    }
  }
}
