.auth-form__wrapper {
  padding: 16px 16px 0;
  width: 100%;

  .auth-form {
    width: 100%;
    max-width: 433px;
    margin: 0 auto;
  }

  @media screen and (min-width: 768px) {
    padding: 16px 0 0;
  }
}
