.observers-list {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .observers__type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;

    .type-name {
      font-weight: 600;
      font-size: 24px;
      line-height: 120%;
      letter-spacing: 0.02em;
      color: var(--black);
    }
  }

  .observer-list__items {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .observer-item {
      width: 49%;
    }

    .no-observers {
      max-width: 655px;
      padding: 0 16px;
      display: flex;
      flex-direction: column;
      p {
        font-size: 18px;
        line-height: 150%;
        letter-spacing: 0.02em;
        color: var(--text);
      }
    }

    .observers__slider {
      width: 100%;
      overflow: hidden;

      .slick-slider {
        position: relative;
        padding-bottom: 36px;
        .slick-list {
          margin: 0 16px;
          .slick-track {
            display: flex;
            .slick-slide {
              margin: 0 4px;
              .observer-item {
                display: flex;
                width: 100%;
                margin-top: 0;
                padding: 24px 0px;
                align-items: center;

                .itvitation__status {
                  span {
                    font-size: 16px;
                  }
                }

                .observer__name {
                  margin-top: 40px;
                  font-size: 28px;
                }

                .observer__email {
                  margin-top: 37px;
                  font-size: 16px;
                }

                .invitation__date {
                  max-width: 190px;
                  text-align: center;
                  font-size: 16px;
                }
              }
            }
          }
        }

        .slick-dots {
          bottom: 0;

          li {
            width: 12px;
            height: 12px;
            margin: 0 3px;
            button {
              padding: 0;
              width: 12px;
              height: 12px;
              &:before {
                content: "";
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: var(--text);
              }
            }
          }
        }
      }
    }
  }

  @media screen and(min-width: 768px) {
    margin-top: 57px;
    padding: 0 16px;

    .observer-list__items {
      .no-observers {
        padding: 0;
      }

      .observers__slider {
        display: none;
      }
    }

    .observers__type {
      padding: 0;

      .type-name {
        font-size: 28px;
      }
    }
  }

  @media screen and(min-width: 1100px) {
    padding: 0;
  }
}
