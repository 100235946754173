.registration-checkbox__wrapper {
  margin-top: 16px;

  &.error {
    .checkbox__item {
      .checkbox__item-box {
        border: 2px solid var(--form-error);
      }
    }
  }

  .checkbox__item {
    .checkbox__item-label {
      a {
        text-decoration: none;
        color: #4d79eb;
        font-weight: 600;
      }
    }

    &--checked {
      .checkbox__item-box::before {
        background-color: #4d79eb;
      }
    }
  } 
}
