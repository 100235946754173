.landing-page__wrapper {
  width: 100%;
  min-height: calc(100vh - var(--footer-height));
  display: flex;
  overflow: hidden;

  .landing-page__section {
    display: flex;
    flex-direction: column;
    min-height: 100%;

    &.info {
      position: relative;
      width: 41%;
      background: linear-gradient(0deg, #4f96f1, #4f96f1),
        linear-gradient(0deg, #72b498, #72b498), #f6f8a3;
      overflow: hidden;
      padding: 64px 0;

      &:after {
        content: "";
        position: absolute;
        width: 770px;
        height: 312px;
        left: -15.09%;
        bottom: 4.79%;
        background: linear-gradient(
          180deg,
          rgba(79, 241, 183, 0.97) 0%,
          #cf62b7 100%
        );
        filter: blur(100px);
        transform: matrix(0.81, 0.13, -1.01, 0.97, 0, 0);
      }
    }

    &.observer {
      width: 59%;
      padding: 200px 0 40px;
    }
  }

  @media screen and (max-width: 1099px) {
    flex-direction: column;
    .landing-page__section {
      &.info {
        width: 100%;
        padding: 44px 16px;

        &::after {
          display: none;
        }

        .info__container {
          align-items: center;

          .info__title {
            margin-top: 20px;
            text-align: center;
            font-size: 28px;
          }

          .info__description {
            margin-top: 12px;
            font-size: 16px;
            text-align: center;
          }
        }
      }

      &.observer {
        width: 100%;
        padding: 32px 16px 40px;
      }
    }
  }

  @media screen and (max-width: 499px) {
    .landing-page__section {
      &.info {
        .info__container {
          .info__description {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;

            &.full-content {
              display: block;
            }
          }
          .show-more__btn {
            margin-top: 20px;
            display: block;
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: 0.02em;
            color: var(--white);
            cursor: pointer;
          }
        }
      }
    }
  }
}
