.form-title__wrapper {
  width: 100%;
  position: relative;
  background: linear-gradient(0deg, #4f96f1, #4f96f1),
    linear-gradient(0deg, #72b498, #72b498), #f6f8a3;
  overflow: hidden;

  &:after {
    content: "";
    z-index: 1;
    position: absolute;
    width: 778px;
    height: 343px;
    left: 57.57%;
    top: -70.71%;
    background: linear-gradient(
      180deg,
      rgba(79, 241, 183, 0.97) 0%,
      #cf62b7 100%
    );
    filter: blur(150px);
    transform: matrix(0.83, 0.16, -0.85, 0.97, 0, 0);
  }

  .form-title__container {
    width: 100%;
    max-width: 1328px;
    margin: 0 auto;
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;

    .title__content {
      display: flex;
      flex-direction: column;
      z-index: 2;

      .title {
        font-weight: 600;
        font-size: 32px;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: var(--white);
      }

      .description {
        font-size: 18px;
        line-height: 150%;
        letter-spacing: 0.02em;
        color: var(--white);
      }
    }

    .title__count {
      display: flex;
      align-items: center;
      width: 156px;
      padding: 12px;
      background: rgba(255, 255, 255, 0.15);
      border-radius: 50px;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.02em;
      color: var(--white);
      z-index: 2;

      .total__icon {
        margin-right: 8px;
      }
    }
  }

  @media screen and (max-width: 1340px) {
    .form-title__container {
      padding: 24px 16px 0;
    }
  }

  @media screen and (max-width: 767px) {
    .form-title__container {
      padding: 48px 16px 0;
      flex-direction: column;

      .title__content {
        text-align: center;
        .title {
          font-size: 28px;
        }

        .description {
          font-size: 16px;
        }
      }

      .title__count {
        margin-top: 24px;
        width: 148px;
        padding: 8px;
      }
    }
  }
}
