.modern-radio-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.radio-outer-circle {
  width: 64px;
  height: 64px;
  min-width: 18px;
  min-height: 18px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s linear;
  background-color: #0d5bc6;

  &.unselected {
    background-color: rgba(13, 91, 198, 0.2);
  }
}

.radio-inner-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  color: #fff;
  border-radius: 50%;
  transition: all 0.1s linear;
}

.helper-text {
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.03em;
  color: #555555;
  margin-left: 15px;
}

@media screen and (max-width: 767px) {
  .radio-outer-circle {
    width: 50px;
    height: 50px;
  }

  .radio-inner-circle {
    width: 34px;
    height: 34px;
  }
}
