.MyButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: none;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 10px rgba(63, 86, 211, 0.2);
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
    box-shadow: none;
  }

  &__support {
    width: 130px;
    height: 48px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    color: var(--blue);
    background: linear-gradient(
        0deg,
        rgba(46, 121, 218, 0.15),
        rgba(46, 121, 218, 0.15)
      ),
      #ffffff;

    .support__logo {
      margin-right: 10px;
    }
  }

  &__create-account {
    margin-top: 32px;
    width: 100%;
    height: 51px;
    color: #222222;
    background: rgba(34, 34, 34, 0.15);
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.12em;
    text-transform: uppercase;
  }

  &__back {
    display: flex;
    align-items: center;
    color: var(--white);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.02em;
    background: none;
    border: none;
  }

  &__logout {
    margin-left: 16px;
    width: 50px;
    height: 48px;
    background: linear-gradient(
        0deg,
        rgba(77, 121, 235, 0.15),
        rgba(77, 121, 235, 0.15)
      ),
      #ffffff;
  }

  &__due-date {
    margin-right: 16px;
    width: 243px;
    height: 52px;
    padding: 4px;
    border: 1px solid #fe9153;
    background-color: var(--white);
    justify-content: start;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: var(--black);
    border-radius: 30px;

    @media screen and (min-width: 768px) {
      border-radius: 12px;
      line-height: 150%;
    }

    .due-date_image {
      margin-right: 8px;
    }
  }

  &__progress-status {
    width: 132px;
    height: 52px;
    margin-top: 8px;
    border: 1px solid #5bcbc9;
    background-color: var(--white);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #5bcbc9;
    border-radius: 30px;

    &.finished {
      border: 1px solid #ce5268;
      color: #ce5268;
    }

    @media screen and (min-width: 768px) {
      margin-top: 0;
      border-radius: 12px;
    }
  }

  &__proceed {
    width: 200px;
    height: 49px;
    background-color: rgba(255, 255, 255, 0.25);
    color: var(--white);
    font-size: 14px;
    line-height: 120%;
    text-transform: uppercase;

    img {
      margin-left: 24px;
    }

    @media screen and (min-width: 1024px) {
      width: 210px;
      height: 51px;
      font-size: 16px;
    }
  }

  &__invite {
    width: 162px;
    height: 51px;
    border: 1px solid var(--black);
    background-color: var(--white);
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.12em;
    color: var(--black);

    span {
      margin-left: 5px;
      text-transform: uppercase;
    }

    &.mobile {
      display: none;
    }

    @media screen and (max-width: 767px) {
      display: none;

      &.required {
        display: block;
        width: 81px;
        span {
          display: none;
        }
      }

      &.mobile {
        margin-top: 40px;
        display: block;
        width: 152px;
      }
    }
  }

  &__report {
    margin-top: 32px;
    width: 190px;
    height: 51px;
    background-color: #f1ba4f;
    color: var(--white);
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.12em;
    text-transform: uppercase;

    @media screen and(max-width: 1131px) {
      margin-top: 40px;
    }
  }

  &__language {
    margin: 0 6px;
    width: 198px;
    height: 72px;
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    border: 1px solid #5bcbc9;
    background: rgba(91, 203, 201, 0.15);
    color: #5bcbc9;

    &.active {
      background: rgba(79, 150, 241, 0.15);
      border: 1px solid #4f96f1;
      color: #4f96f1;
    }

    .language__icon {
      margin-right: 14px;
    }

    @media screen and (max-width: 767px) {
      width: 256px;
      height: 64px;
      margin: 4px 0;
      font-size: 14px;

      .language__icon {
        margin-right: 30px;
      }
    }
  }

  &__continue {
    width: 178px;
    height: 51px;
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    color: var(--black);
    background: rgba(34, 34, 34, 0.15);
    transition: 0.3s all;

    &:disabled {
      opacity: 0.5;
    }

    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }

  &__questionnaire-submit {
    width: 140px;
    height: 51px;
    background: rgba(34, 34, 34, 0.15);
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    color: var(--black);
    text-transform: uppercase;
    letter-spacing: 0.12em;

    @media screen and (max-width: 600px) {
      font-size: 14px;
      height: 49px;
    }

    img {
      margin-left: 24px;
    }
  }

  &__questionnaire-back {
    margin-right: 10px;
    width: 140px;
    height: 51px;
    background: rgba(34, 34, 34, 0.15);
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.12em;
    color: var(--black);
    text-transform: uppercase;

    @media screen and (max-width: 600px) {
      font-size: 14px;
      height: 49px;
    }

    img {
      margin-right: 24px;
      transform: rotate(180deg);
    }
  }

  &__observer {
    width: 200px;
    height: 51px;
    border: 1px solid var(--black);
    border-radius: 12px;
    text-transform: uppercase;
    background: transparent;
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.12em;
    color: var(--black);

    @media screen and (max-width: 600px) {
      width: 100%;
      font-size: 14px;
      height: 49px;
      margin-bottom: 36px;
    }
  }

  &__cancel {
    margin-right: 16px;
    width: 140px;
    height: 51px;
    border: 1px solid var(--black);
    border-radius: 12px;
    text-transform: uppercase;
    background: transparent;
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.12em;
    color: var(--black);

    @media screen and (max-width: 600px) {
      font-size: 14px;
    }
  }

  &__observer-invitation {
    margin-top: 12px;
    width: 192px;
    height: 51px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(63, 86, 211, 0.2);
    border-radius: 12px;
  }
}
