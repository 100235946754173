section.campaign-observers {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding-bottom: 80px;

  @media screen and(max-width: 1150px) {
    // padding: 0 16px 80px;
  }
}
