.modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.48);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  z-index: 1000;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  .modal__content {
    border-radius: 12px;
    background-color: var(--white);
    transform: scale(0.5);
    transition: 0.4s all;

    &.active {
      transform: scale(1);
    }
  }

  @media screen and (max-width: 767px) {
    .modal__content {
      width: 90%;
    }
  }
}
