.form-tabs__wrapper {
  margin-top: 27px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);

  .tabs__container {
    width: 100%;
    max-width: 1328px;
    margin: 0 auto;
    display: flex;
    flex-wrap: nowrap;

    .tab__item {
      position: relative;
      padding: 24px 0 15px;
      font-weight: 600;
      font-size: 20px;
      line-height: 120%;
      letter-spacing: 0.02em;
      white-space: nowrap;
      color: #cde3ff;

      margin-right: 24px;
      cursor: pointer;
      z-index: 2;

      &.active {
        color: var(--white);

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 4px;
          background-color: #ffd029;
        }
      }
    }
  }

  @media screen and (max-width: 1340px) {
    .tabs__container {
      padding: 0 24px;
    }
  }

  @media screen and (max-width: 767px) {
    margin-top: 40px;
    .tabs__container {
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .tab__item {
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
