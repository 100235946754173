.campaign-step__item {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid #888888;

  .item__step {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 40px;
    background-color: var(--white);
    border-radius: 12px;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: var(--text);
    background: rgba(85, 85, 85, 0.15);
  }

  .item__info {
    margin-top: 12px;
    .info__description {
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.02em;
      color: var(--text);
    }

    .info__title {
      font-weight: 600;
      font-size: 28px;
      line-height: 120%;
      letter-spacing: 0.02em;
      color: var(--text);
    }
  }

  .item__button {
    margin-top: 48px;

    .lock__message {
      display: flex;
      align-items: center;
      height: 50px;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.02em;
      color: var(--text);

      img {
        margin-right: 10px;
      }
    }

    .completed-date {
      display: flex;
      align-items: center;
      height: 50px;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.02em;
      color: #5bcbc9;
    }
  }

  &--active {
    background: #4d79eb;
    border: none;

    .item__step {
      color: #4d79eb;
      background: var(--white);
    }

    .item__info {
      .info__description {
        color: #e6e6e6;
      }
      .info__title {
        color: var(--white);
      }
    }
  }

  &--completed {
    border: none;
    background: linear-gradient(
        0deg,
        rgba(91, 203, 201, 0.15),
        rgba(91, 203, 201, 0.15)
      ),
      #ffffff;

    .item__step {
      background: #5bcbc9;
      color: var(--white);
    }

    .item__info {
      .info__description {
        color: #5bcbc9;
      }
      .info__title {
        color: #5bcbc9;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    padding: 32px 40px;

    .item__step {
      width: 120px;
      height: 51px;
      font-size: 18px;
    }

    .item__info {
      margin-top: 20px;
      .info__description {
        font-size: 18px;
      }

      .info__title {
        font-size: 32px;
      }
    }

    .item__button {
      margin-top: 92px;

      .lock__message,
      .completed-date {
        font-size: 18px;
      }
    }
  }
}
