.language-selection__wrapper {
  position: relative;
  padding: 80px 56px;

  .close__btn {
    position: absolute;
    top: 27px;
    right: 27px;
    cursor: pointer;
  }

  .language-selection__content {
    width: 100%;
    max-width: 544px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content__title {
      font-weight: 600;
      font-size: 32px;
      line-height: 120%;
      letter-spacing: 0.02em;
      color: var(--black);
      text-align: center;
    }

    .content__description {
      margin-top: 16px;
      font-size: 18px;
      line-height: 150%;
      letter-spacing: 0.02em;
      text-align: center;
      color: var(--text);
    }

    .selection__buttons {
      margin-top: 32px;
      display: flex;
      justify-content: center;
    }

    .continue__button {
      margin-top: 56px;
      display: flex;
      justify-content: center;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 72px 18px 24px;

    .close__btn {
      position: absolute;
      top: 24px;
      right: 18px;
    }

    .language-selection__content {
      .content__title {
        font-size: 28px;
      }

      .content__description {
        margin-top: 12px;
        font-size: 16px;
      }

      .selection__buttons {
        margin-top: 20px;
        flex-direction: column;
      }

      .continue__button {
        margin-top: 32px;
      }
    }
  }
}
