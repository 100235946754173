.finished-campaign-modal__wrapper {
  position: relative;
  padding: 64px 120px 88px;

  .close__btn {
    position: absolute;
    top: 27px;
    right: 27px;
    cursor: pointer;
  }

  .finished-campaign-modal__content {
    width: 100%;
    max-width: 416px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 600;
    font-size: 28px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: var(--black);
    text-align: center;
  }

  @media screen and (max-width: 767px) {
    padding: 48px 30px;
    display: flex;
    justify-content: center;

    .close__btn {
      position: absolute;
      top: 24px;
      right: 18px;
    }

    .finished-campaign-modal__content {
      font-size: 20px;
    }
  }
}
