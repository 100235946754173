footer.footer {
  width: 100%;
  height: var(--footer-height);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-top: 1px solid var(--light);
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.02em;
  color: #707479;
  background: #f9fbfe;

  @media screen and(min-width: 1100px) {
    flex-direction: row;

    .footer-text {
      margin-left: 8px;

      h1,
      h2,
      h3,
      h4,
      h5,
      p {
        font-size: 16px;
        line-height: 120%;
        font-weight: normal;
      }
    }
  }
}
