header.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: var(--header-height);
  background-color: #fff;
  padding: 0 22px;

  &__hidden {
    display: none;
  }

  .main-logo {
    width: 70px;
    height: auto;
  }

  .school-logo {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    height: 41px;
    margin-left: 15px;

    img {
      width: auto;
      height: fit-content;
    }
  }

  @media screen and (min-width: 1024px) {
    padding: 0 56px;

    .main-logo {
      width: auto;
      height: auto;
    }

    .school-logo {
      display: flex;
      align-items: center;
      flex: 1 1 auto;
      height: 41px;
      margin-left: 25px;
      padding-left: 16px;
      border-left: 1px solid var(--light);
    }
  }
}
