.progress-bar__container {
  width: 100%;
  height: 16px;
  display: flex;
  background: #e6ebf2;
  border-radius: 8px;
  padding: 4px;

  .progress-bar__filler {
    background: #b9c7da;
    border-radius: 8px;
    transition: 0.5s all;
  }
}
