.input__wrapper {
  display: flex;
  align-items: center;
  margin-top: 16px;
  padding: 0 24px;
  width: 100%;
  height: 64px;
  border: 1px solid #e6ebf2;
  border-radius: var(--border-radius);
  position: relative;
  background-color: #fff;

  &--error {
    border: 1px solid var(--form-error);
  }

  &:focus-within {
    border: 1px solid #4d79eb;
  }

  .registration__input {
    margin-top: 10px;
    width: 100%;
    border: none;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #222222;
  }

  .input-label {
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translate(0, -50%);
    pointer-events: none;
    transition: 0.2s ease all;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #555555;

    &--active {
      font-size: 12px;
      top: 18px;
    }
  }

  .registration__input:focus ~ .input-label {
    font-size: 12px;
    top: 18px;
    color: #4d79eb;
  }

  .icon {
    cursor: pointer;

    &.is-shown {
      path {
        fill: var(--blue);
      }
    }
  }
}

.field__error {
  margin-top: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: var(--form-error);
}

@media screen and (min-width: 1024px) {
  .input__wrapper {
    .registration__input {
      font-size: 18px;
    }

    .input-label {
      font-size: 18px;

      &--active {
        font-size: 12px;
        top: 18px;
      }
    }

    .registration__input:focus ~ .input-label {
      font-size: 12px;
      top: 18px;
    }
  }

  .field__error {
    font-size: 14px;
  }
}
