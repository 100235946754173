*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Inter", sans-serif;

  &.lock {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

input,
button,
select {
  font: inherit;
  outline: none;
  padding: 0;
}

button.reset-style {
  font: inherit;
  color: inherit;
  background-color: transparent;
  border: none;
}

.main__wrapper {
  width: 100%;
  min-height: calc(100vh - var(--header-height) - var(--footer-height));
  background: #f9fbfe;
}

:root {
  // colors
  --white: #fff;
  --black: #222222;
  --blue: #2e79da;
  --light: #e6ebf2;
  --footer-background: #f9fbfe;
  --form-error: #f1565f;
  --text: #555555;

  //  element sizes
  --header-height: 73px;
  --footer-height: 72px;
  --border-radius: 12px;

  // screens
  --mobile: 375px;
  --tablet: 768px;
  --laptop: 1024px;
  --desktop: 1280px;
  --large-screen: 1440px;

  --min-width: 1024px;
}
