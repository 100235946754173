.step-items__section {
  padding: 24px 16px 60px;
  width: 100%;
  max-width: 1328px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .campaign-step__item {
    width: 100%;

    &:nth-child(1) {
      margin-bottom: 24px;
    }
  }
  @media screen and (min-width: 1024px) {
    padding: 24px 24px 60px;
  }

  @media screen and (min-width: 1100px) {
    padding: 40px;
    flex-direction: row;

    .campaign-step__item {
      width: 49%;

      &:nth-child(1) {
        margin-bottom: 0;
      }
    }
  }

  @media screen and (min-width: 1400px) {
    padding: 40px 0;
  }
}

.complited-item__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 24px 16px 0;
  background: linear-gradient(
      0deg,
      rgba(91, 203, 201, 0.15),
      rgba(91, 203, 201, 0.15)
    ),
    #ffffff;
  padding: 14px 24px 52px;
  border-radius: 12px;

  .complited-item__image {
    order: 2;
  }
  .complited-item__content {
    order: 3;
    display: flex;
    flex-direction: column;
    margin: 28px 0 0 0;
    width: 100%;

    .content__title {
      font-weight: 600;
      font-size: 28px;
      line-height: 120%;
      letter-spacing: 0.02em;
      color: #5bcbc9;
      text-align: center;
    }
    .content__date {
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.02em;
      color: #5bcbc9;
      text-align: center;
    }
  }

  .complited-item__status {
    order: 1;
    width: 135px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(91, 203, 201, 0.2);
    border-radius: 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #5bcbc9;
  }

  @media screen and (min-width: 768px) {
    padding: 20px 28px 20px 40px;

    .complited-item__image {
      order: 1;
    }
    .complited-item__content {
      order: 2;
      flex: 1 1 auto;
      margin: 0 0 0 14px;
      width: unset;

      .content__title {
        font-size: 32px;
        text-align: left;
      }
      .content__date {
        font-size: 18px;
        text-align: left;
      }
    }

    .complited-item__status {
      order: 3;
    }
  }

  @media screen and (min-width: 1140px) {
    margin: 40px auto 0;
    max-width: 1100px;
    width: 100%;
  }
}
