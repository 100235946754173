section.registration-section {
  width: 100%;
  min-height: calc(100vh - var(--footer-height));
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .registration-title__section {
    position: relative;
    padding: 44px 16px;
    width: 100%;
    min-height: 100%;
    background: linear-gradient(0deg, #4f96f1, #4f96f1),
      linear-gradient(0deg, #72b498, #72b498), #f6f8a3;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .title__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 392px;
      margin: 0 auto;
      flex: 1 1 auto;

      .container__logo {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      .container__title {
        margin-top: 24px;
        font-weight: 600;
        font-size: 28px;
        line-height: 120%;
        color: var(--white);
        letter-spacing: 0.02em;
        z-index: 2;
        text-align: center;
      }

      .container__description {
        margin-top: 12px;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: var(--white);
        letter-spacing: 0.02em;
        z-index: 2;
        text-align: center;
      }
    }

    .decorate-image {
      display: none;
    }
  }

  .form__section {
    padding: 30px 0 56px;
    width: 100%;
    min-height: 100%;
    display: flex;
    // justify-content: center;
    // align-items: center;

    .form__container {
      width: 100%;
      max-width: 433px;
      margin: 0 auto;

      .form__title {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: #222222;
        text-align: center;
      }
    }
  }

  @media screen and (min-width: 1100px) {
    flex-direction: row;

    .registration-title__section {
      padding: 64px 0 0;
      width: 41%;

      .decorate-image {
        display: block;
        width: 100%;
      }

      // &:after {
      //   content: "";
      //   position: absolute;
      //   width: 770px;
      //   height: 312px;
      //   left: -15.09%;
      //   bottom: 4.79%;
      //   background: linear-gradient(
      //     180deg,
      //     rgba(79, 241, 183, 0.97) 0%,
      //     #cf62b7 100%
      //   );
      //   filter: blur(100px);
      //   transform: matrix(0.81, 0.13, -1.01, 0.97, 0, 0);
      //   z-index: 1;
      // }

      .title__container {
        .container__logo {
          justify-content: flex-start;
        }

        .container__title {
          margin-top: 56px;
          font-size: 32px;
          text-align: left;
        }

        .container__description {
          margin-top: 24px;
          font-size: 18px;
          text-align: left;
        }
      }
    }

    .form__section {
      padding: 110px 30px 130px;
      width: 59%;

      .form__container {
        .form__title {
          font-size: 40px;
        }
      }
    }
  }
}
