.response-error {
  width: 100%;
  text-align: center;
  margin-top: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: var(--form-error);

  @media screen and (min-width: 1024px) {
    font-size: 16px;
  }
}
