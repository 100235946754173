.observer__container {
  margin: 0 auto;
  width: 100%;
  max-width: 544px;

  .observer__title {
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: var(--black);
  }

  .observer__name {
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: var(--black);
  }

  .MyButton.full-width {
    margin-top: 32px;
    width: 100%;
  }
}

.observer-message__wrapper {
  margin: 0 auto;
  padding: 36px 68px 58px;
  width: 100%;
  max-width: 544px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 6px 6px 12px #dfe6f0;
  border-radius: 12px;
  background-color: var(--white);

  .success-message__container {
    .content__title {
      font-weight: 600;
      font-size: 24px;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.02em;
      color: #4f96f1;
    }

    .content__description {
      margin-top: 8px;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      text-align: center;
      letter-spacing: 0.02em;
      color: #888888;
    }
  }
}

@media screen and (max-width: 499px) {

  .observer__container {
    .observer__title {
      font-size: 32px;
    }

    .observer__name {
      font-size: 20px;
    }
  }
  .observer-message__wrapper {
    padding: 32px 16px;

    .success-message__container {
      .content__title {
        margin-top: 16px;
        font-size: 20px;
      }
    }
  }
}
