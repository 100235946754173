.campaign__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 6px 6px 12px #dfe6f0;
  background-color: var(--white);

  &.not-registered {
    margin-top: 24px;
    text-decoration: none;

    @media screen and (min-width: 768px) {
      margin-top: 40px;
    }
  }

  .campaign__image {
    border-radius: 12px;
    width: 100%;

    img {
      border-radius: 12px;
      width: 100%;
      height: auto;
    }
  }

  .campaign__content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    .campaign__title {
      width: 100%;
      margin-top: 20px;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 120%;
      letter-spacing: 0.02em;
      color: var(--black);
    }

    .campaign__description {
      margin-top: 8px;
      width: 100%;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.02em;
      color: #555555;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .campaign__info {
      margin-top: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;

      .register-link {
        text-decoration: none;
      }
    }
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;

    .campaign__image {
      margin-right: 32px;
      width: 198px;
      height: 198px;

      img {
        width: 198px;
        height: 198px;
        object-fit: cover;
      }
    }

    .campaign__content {
      .campaign__title {
        margin-top: 0;
        font-size: 22px;
      }

      .campaign__description {
        font-size: 18px;
        -webkit-line-clamp: 2;
      }

      .campaign__info {
        margin-top: 28px;
        flex-direction: row;
      }
    }
  }
}
