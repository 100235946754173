section.title__section {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 58px 16px 40px;
  overflow: hidden;
  z-index: 1;
  background: linear-gradient(0deg, #4f96f1, #4f96f1),
    linear-gradient(0deg, #72b498, #72b498), #f6f8a3;

  &--image {
    padding: 50px 16px 32px;

    &:after {
      content: "";
      z-index: 1;
      position: absolute;
      width: 512px;
      height: 320px;
      left: -7.86%;
      top: -47.09%;
      background: linear-gradient(
        180deg,
        rgba(79, 241, 183, 0.28) 0%,
        rgba(207, 98, 183, 0.65) 100%
      );
      filter: blur(100px);
      transform: matrix(0.9, 0.3, -0.47, 0.94, 0, 0);
    }
  }

  .back__button {
    position: absolute;
    top: 20px;
    left: 22px;
    cursor: pointer;

    .button__arrow {
      margin-right: 14px;
    }
  }

  .section__content {
    z-index: 2;

    &--image {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .content__text {
        width: 100%;
      }

      .content__image {
        display: none;
      }

      .content__text {
        .section__title,
        .section__description {
          text-align: left;
        }

        .section__description {
          margin-top: 12px;
        }
      }
    }
    .content__text {
      .section__title {
        font-weight: 600;
        font-size: 28px;
        line-height: 120%;
        color: var(--white);
        letter-spacing: 0.02em;
        text-align: center;
      }

      .section__description {
        margin-top: 4px;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: var(--white);
        text-align: center;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    padding: 35px 16px;

    &--image {
      padding: 0 16px;

      &:after {
        content: "";
        z-index: 1;
        position: absolute;
        width: 806px !important;
        height: 505px !important;
        left: 57.57% !important;
        top: -51% !important;
        background: linear-gradient(
          180deg,
          rgba(79, 241, 183, 0.28) 0%,
          rgba(207, 98, 183, 0.65) 100%
        ) !important;
        filter: blur(100px) !important;
        transform: matrix(0.9, 0.3, -0.47, 0.94, 0, 0) !important;
      }
    }

    &:after {
      content: "";
      z-index: 1;
      position: absolute;
      width: 779px;
      height: 346px;
      left: 57.57%;
      top: -71%;
      background: linear-gradient(
        180deg,
        rgba(79, 241, 183, 0.97) 0%,
        #cf62b7 100%
      );
      filter: blur(100px);
      transform: matrix(0.83, 0.16, -0.83, 0.97, 0, 0);
    }

    .back__button {
      left: 60px;
    }

    .section__content {
      max-width: 1100px;
      width: 100%;
      margin: 0 auto;

      &--image {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .content__text {
          width: 49%;
          padding: 35px 0;

          &--with-image {
            margin: 90px 0;
          }
        }
        .content__image {
          width: 49%;
          display: flex;
          justify-content: center;
        }

        .content__text {
          .section__title,
          .section__description {
            text-align: left;
          }

          .section__description {
            margin-top: 12px;
          }
        }
      }

      .content__text {
        .section__title {
          font-size: 32px;
        }

        .section__description {
          font-size: 18px;
          letter-spacing: 0.02em;
        }
      }
    }
  }
}
