.info__container {
  margin: 0 auto;
  width: 100%;
  max-width: 392px;
  display: flex;
  flex-direction: column;
  z-index: 1;

  .info__title {
    margin-top: 200px;
    font-weight: 600;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: var(--white);
  }

  .info__description {
    margin-top: 24px;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: var(--white);
  }

  .show-more__btn {
    display: none;
  }

  @media screen and (max-width: 1099px) {
    align-items: center;

    .info__title {
      margin-top: 20px;
      text-align: center;
      font-size: 28px;
    }

    .info__description {
      margin-top: 12px;
      font-size: 16px;
      text-align: center;
    }
  }

  @media screen and (max-width: 499px) {
    .info__description {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;

      &.full-content {
        display: block;
      }
    }
    .show-more__btn {
      margin-top: 20px;
      display: block;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.02em;
      color: var(--white);
      cursor: pointer;
    }
  }
}
