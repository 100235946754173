section.report-section {
  width: 100%;
  margin-top: 24px;
  padding: 0 16px;

  .section__content {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    padding: 40px 19px 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    background: linear-gradient(
        0deg,
        rgba(241, 186, 79, 0.2),
        rgba(241, 186, 79, 0.2)
      ),
      #ffffff;

    img {
      width: 46px;
      height: auto;
    }

    .report-title {
      margin-top: 24px;
      font-weight: 600;
      font-size: 24px;
      line-height: 120%;
      text-align: center;
      letter-spacing: 0.02em;
      color: var(--text);
    }

    .report-description {
      margin-top: 16px;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.02em;
      color: var(--text);
    }
  }

  @media screen and(min-width: 1132px) {
    margin-top: 40px;
    padding: 0;

    .section__content {
      padding: 40px 136px 48px;

      .report-title {
        font-size: 28px;
      }

      .report-description {
        font-size: 18px;
      }
    }
  }
}
