.observers-form__section {
  width: 100%;

  .form__container {
    width: 100%;
    max-width: 1328px;
    margin: 0 auto;

    .form__buttons {
      width: 100%;
      margin-top: 36px;
      display: flex;
      justify-content: space-between;

      .send-and-cancel {
        display: flex;
      }
    }
  }

  @media screen and(max-width: 1340px) {
    .form__container {
      padding: 0 0 50px;
    }
  }

  @media screen and(max-width: 600px) {
    .form__container {
      .form__buttons {
        flex-direction: column;

        .send-and-cancel {
          justify-content: center;
        }
      }
    }
  }
}
