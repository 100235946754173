.success-message__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .content__icon {
    width: 100px;
    height: 100px;
  }

  .content__title {
    margin-top: 24px;
    font-weight: 600;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: var(--black);
    text-align: center;
  }

  .content__description {
    margin-top: 16px;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.02em;
    color: var(--text);
  }

  @media screen and (max-width: 767px) {
    .content__icon {
      width: 80px;
      height: 80px;
    }

    .content__title {
      margin-top: 16px;
      font-size: 28px;
    }

    .content__description {
      margin-top: 12px;
      font-size: 16px;
    }

    .btn__wrapper {
      margin-top: 20px;
    }
  }
}
