.MyLink {
  &__registration {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    color: #4d79eb;

    @media screen and (min-width: 1024px) {
      font-size: 18px;
    }
  }

  &__campaign {
    margin-top: 24px;
    text-decoration: none;

    @media screen and (min-width: 768px) {
      margin-top: 40px;
    }
  }
}
