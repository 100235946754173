section.questionnaire-form {
  width: 100%;
  max-width: 1110px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  .progress-bar__container {
    margin-top: 34px;
  }

  .form__container {
    margin-top: 52px;

    .informative-panel__wrapper {
      p {
        font-size: 18px;
        line-height: 25px;
        color: var(--text);

        &.middle {
          margin: 20px 0;
        }
      }
    }

    p.error-message {
      margin-top: 10px;
      font-size: 18px;
      line-height: 25px;
      color: var(--form-error);
    }

    .form__buttons {
      margin: 55px 0px;
      display: flex;
      justify-content: flex-start;
    }

    .form-question {
      font-size: 24px;
      line-height: 150%;
      letter-spacing: 0.02em;
      font-weight: 500;
      color: #222222;
    }

    .question-description {
      margin-top: 10px;
      font-style: italic;
      color: #222222;
    }

    .container {
      margin-top: 24px;
      width: 100%;
      display: flex;
      flex-direction: column;
      border-radius: 12px;
      background-color: #fff;
      padding: 48px 40px;
    }

    .range__container {
      .radio__list {
        width: 100%;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 767px) {
          flex-direction: column;
          align-items: center;

          .modern-radio-container {
            margin: 10px 0;
          }
        }
      }

      .criteria.mobile {
        display: none;

        @media screen and (max-width: 767px) {
          display: block;
          text-align: center;

          &.max {
            margin-top: 10px;
          }
        }
      }

      .radio__criterias {
        width: 100%;
        margin-top: 25px;
        display: flex;
        justify-content: space-between;

        .criteria {
          font-size: 16px;
          line-height: 150%;
          letter-spacing: 0.03em;
          color: #555555;
          display: block;

          &.min {
            width: 150px;
          }

          &.max {
            width: 185px;
            text-align: right;
          }
        }

        @media screen and (max-width: 767px) {
          display: none;
        }
      }
    }

    .checkbox__container {
      .checkbox__item {
        margin: 10px 0;
      }
    }

    .dropdown-select {
      margin-top: 24px;
    }

    .radio__container {
      .modern-radio-container {
        margin: 15px 0;
      }
    }

    .textarea {
      margin-top: 24px;
      width: 100%;
      height: 128px;
      background-color: #fff;
      border-radius: 12px;
      border: none;
      padding: 20px 24px;

      &:focus {
        outline: 1px solid rgba(13, 91, 198, 0.2);
      }
    }

    @media screen and (max-width: 767px) {
      margin-top: 40px;

      .container {
        padding: 24px 20px;
      }

      .form__buttons {
        margin-top: 40px;
        justify-content: center;
      }
    }
  }
}
