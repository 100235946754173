.add-observers-modal__wrapper {
  position: relative;
  padding: 64px 120px 88px;

  .close__btn {
    position: absolute;
    top: 27px;
    right: 27px;
    cursor: pointer;
  }

  .add-observers-modal__content {
    width: 100%;
    max-width: 416px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content__title {
      margin-top: 24px;
      font-weight: 600;
      font-size: 32px;
      line-height: 120%;
      letter-spacing: 0.02em;
      color: var(--black);
      text-align: center;
    }

    .content__description {
      margin-top: 16px;
      font-size: 18px;
      line-height: 150%;
      letter-spacing: 0.02em;
      text-align: center;
      color: var(--text);

      span {
        font-weight: bold;
      }
    }

    .content__buttons {
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .add-later__button {
        margin-top: 24px;
        font-weight: bold;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: 0.12em;
        text-transform: uppercase;
        color: var(--black);
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 767px) {
    padding: 48px 16px 56px;

    .close__btn {
      position: absolute;
      top: 24px;
      right: 18px;
    }

    .add-observers-modal__content {
      width: 100%;
      max-width: unset;
      display: flex;
      flex-direction: column;
      align-items: center;

      .content__title {
        margin-top: 20px;
        font-size: 28px;
      }

      .content__description {
        margin-top: 12px;
        font-size: 16px;
      }

      .content__buttons {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .add-later__button {
          font-size: 14px;
        }
      }
    }
  }
}
