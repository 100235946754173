.invitation-details__wrapper {
  margin-top: 24px;
  padding: 36px 33px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 6px 6px 12px #dfe6f0;
  border-radius: 12px;
  background-color: var(--white);

  .invitation__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #4f96f1;
  }

  .invitation__due-date {
    margin-top: 24px;
    padding: 0 4px;
    display: flex;
    align-items: center;
    width: 243px;
    height: 52px;
    border: 1px solid #fe9153;
    border-radius: 12px;
    font-size: 16px;
    line-height: 150%;
    font-weight: 500;
    color: var(--black);

    img {
      margin-right: 8px;
    }
  }

  .invitation__info {
    margin-top: 24px;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #888888;
  }

  @media screen and (max-width: 1099px) {
    padding: 32px 16px 40px;

    .invitation__title {
      font-size: 20px;
    }

    .invitation__due-date,
    .invitation__info {
      margin-top: 12px;
    }
  }
}
