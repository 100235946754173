.exist-observer__wrapper {
  margin-top: 16px;
  width: 100%;
  .exist-observers__list {
    display: flex;
    width: 100%;
    margin: 0 auto;
    .exist-observer__item {
      margin-right: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(63, 86, 211, 0.2);
      border-radius: 12px;
      padding: 17px 24px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #3f56d3;
      white-space: nowrap;

      &:last-child {
        margin-right: 0;
      }

      svg {
        margin-right: 10px;
      }
    }
  }

  @media screen and (max-width: 1340px) {
    .exist-observers__list {
      padding: 0 16px;
    }
  }

  @media screen and (max-width: 767px) {
    .exist-observers__list {
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
