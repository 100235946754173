.logout__wrapper {
  display: flex;
  align-items: center;

  .user-info {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.03em;
    color: var(--black);
    display: flex;
    flex-direction: column;
    text-align: right;

    &.campaign {
      display: none;
    }
  }

  @media screen and (min-width: 1024px) {
    .user-info {
      font-size: 18px;
      flex-direction: row;
      text-align: left;

      &.campaign {
        display: flex;
      }

      .user-firstname {
        margin-right: 5px;
      }
    }
  }
}
