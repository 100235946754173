.observers-type__info {
  margin-top: 12px;
  display: flex;

  .info__required,
  .info__total {
    padding-top: 12px;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: var(--black);

    img {
      margin-right: 8px;
    }
  }

  .info__required {
    margin-right: 18px;
  }

  .not-required {
    display: flex;
    align-items: center;
    padding: 16px 19.5px;
    background: linear-gradient(
        0deg,
        rgba(77, 216, 235, 0.2),
        rgba(77, 216, 235, 0.2)
      ),
      #ffffff;
    border: 1px solid rgba(77, 216, 235, 0.3);
    border-radius: 34px;

    img {
      margin-right: 10px;
    }

    .text__content {
      display: flex;
      flex-direction: column;

      .text {
        font-size: 12px;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: #1a9daf;
      }
    }
  }

  @media screen and (max-width: 1340px) {
    padding: 0 16px;
  }

  @media screen and (max-width: 600px) {
    justify-content: space-between;

    .info__required,
    .info__total {
      font-size: 14px;
    }
  }
}
