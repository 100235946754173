.observer-item {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 40px 36px;
  border-radius: 12px;
  background: linear-gradient(
      0deg,
      rgba(63, 86, 211, 0.15),
      rgba(63, 86, 211, 0.15)
    ),
    #ffffff;

  .invitation__status {
    width: 192px;
    height: 51px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(63, 86, 211, 0.2);
    border-radius: 12px;

    span {
      margin-left: 12px;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #3f56d3;
    }
  }

  .observer__name {
    margin-top: 32px;
    font-weight: 600;
    font-size: 30px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #3f56d3;
  }

  .observer__email,
  .invitation__date {
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #3f56d3;
  }

  .observer__email {
    margin-top: 12px;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
}
